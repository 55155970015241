/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()
  return <>
    <SidebarMenuItem
      to='/demo'
      icon='element-11'
      title='Beds'
      fontIcon='bi-app-indicator'
    />
    {process.env.REACT_APP_MODE == 'demo' && (<SidebarMenuItem
      to='/monitoring'
      icon='element-11'
      title='Monitoring'
      fontIcon='bi-app-indicator'
      />)}
  </>
}

export { SidebarMenuMain }
