import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation, faCircleExclamation, faCheck, faXmark, faMinus, faExclamation, faPersonFalling, faPersonFallingBurst, faPlug, faPlugCircleXmark, faWifi, faBed, faBell, faBellSlash, faPerson, faPersonDress, faMars, faVenus, faMattressPillow, faLock, faLockOpen, faArrowDown, faArrowUp, faShoePrints } from '@fortawesome/free-solid-svg-icons'
import {Modal, Button} from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
// TODO: top nav with floors dropdown
// TODO: call backend for beds list

//////////////////////////////////////////////////////////////////////////////
// Temporary Placeholder Data

const TEMP_FLOORS = {
  "location": "LSC",
  "floors": [
    {
      "floor": "Tower 1",
      "beds": {
        "total": 55,
        "armed": 53,
        "unarmed": 2
      }
    },
    {
      "floor": "Tower 2",
      "beds": {
        "total": 55,
        "armed": 55,
        "unarmed": 0
      }
    },
    {
      "floor": "Tower 3",
      "beds": {
        "total": 55,
        "armed": 50,
        "unarmed": 5
      }
    },
    {
      "floor": "ER 1",
      "beds": {
        "total": 30,
        "armed": 30,
        "unarmed": 0
      }
    }
  ]
}
const TEMP_BEDS = {
  "beds": [
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "500-A",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": false,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": "male",
      "weight": 180,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "500-B",
      "online": true,
      "patientInBed": null,
      "bedExitArmed": null,
      "bedExit": null,
      "highFallRisk": null,
      "fallRiskViolation": null,
      "headLeftSideRailUp": null,
      "headRightSideRailUp": null,
      "footLeftSideRailUp": null,
      "footRightSideRailUp": null,
      "sex": "male",
      "weight": 123,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "502",
      "online": true,
      "patientInBed": null,
      "bedExitArmed": null,
      "bedExit": null,
      "highFallRisk": null,
      "fallRiskViolation": null,
      "headLeftSideRailUp": null,
      "headRightSideRailUp": null,
      "footLeftSideRailUp": null,
      "footRightSideRailUp": null,
      "sex": "female",
      "weight": 211,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "503",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": true,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": "male",
      "weight": 146,
      "bedLow": true,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "504",
      "online": true,
      "patientInBed": false,
      "bedExitArmed": false,
      "bedExit": false,
      "highFallRisk": true,
      "fallRiskViolation": true,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": true,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": true,
      "sex": null,
      "weight": 139,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "505",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": false,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": "male",
      "weight": 193,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "506",
      "online": true,
      "patientInBed": false,
      "bedExitArmed": false,
      "bedExit": false,
      "highFallRisk": true,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": true,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": true,
      "sex": "female",
      "weight": 172,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "507",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": true,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": "female",
      "weight": 208,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": true
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "508",
      "online": true,
      "patientInBed": false,
      "bedExitArmed": false,
      "bedExit": false,
      "highFallRisk": true,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": true,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": true,
      "sex": "male",
      "weight": 97,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "509",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": false,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": null,
      "weight": 165,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "510",
      "online": true,
      "patientInBed": false,
      "bedExitArmed": false,
      "bedExit": false,
      "highFallRisk": true,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": true,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": true,
      "sex": "female",
      "weight": 139,
      "bedLow": true,
      "brakesEngaged": true,
      "headOfBed": true
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "511",
      "online": false,
      "patientInBed": null,
      "bedExitArmed": null,
      "bedExit": null,
      "highFallRisk": null,
      "fallRiskViolation": null,
      "headLeftSideRailUp": null,
      "headRightSideRailUp": null,
      "footLeftSideRailUp": null,
      "footRightSideRailUp": null,
      "sex": "female",
      "weight": 118,
      "bedLow": false,
      "brakesEngaged": false,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "512",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": true,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": null,
      "weight": 177,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "513",
      "online": true,
      "patientInBed": false,
      "bedExitArmed": false,
      "bedExit": false,
      "highFallRisk": true,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": true,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": true,
      "sex": "male",
      "weight": 104,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "514",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": false,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": "male",
      "weight": 263,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "515",
      "online": true,
      "patientInBed": false,
      "bedExitArmed": false,
      "bedExit": false,
      "highFallRisk": true,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": true,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": true,
      "sex": "male",
      "weight": 190,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "516",
      "online": false,
      "patientInBed": null,
      "bedExitArmed": null,
      "bedExit": null,
      "highFallRisk": null,
      "fallRiskViolation": null,
      "headLeftSideRailUp": null,
      "headRightSideRailUp": null,
      "footLeftSideRailUp": null,
      "footRightSideRailUp": null,
      "sex": "female",
      "weight": 147,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "517",
      "online": true,
      "patientInBed": true,
      "bedExitArmed": true,
      "bedExit": true,
      "highFallRisk": false,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": false,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": false,
      "sex": null,
      "weight": 131,
      "bedLow": false,
      "brakesEngaged": false,
      "headOfBed": false
    },
    {
      "location": "LSC",
      "floor": "Tower 5",
      "bed": "518",
      "online": true,
      "patientInBed": false,
      "bedExitArmed": false,
      "bedExit": false,
      "highFallRisk": true,
      "fallRiskViolation": false,
      "headLeftSideRailUp": true,
      "headRightSideRailUp": true,
      "footLeftSideRailUp": true,
      "footRightSideRailUp": true,
      "sex": "male",
      "weight": 120,
      "bedLow": false,
      "brakesEngaged": true,
      "headOfBed": false
    }
  ]
}

//////////////////////////////////////////////////////////////////////////////
// Demo Beds Changing Over Time

const BED_CHANGE_RATE_MS = 500
const CHANCE_OF_BED_CHANGE = 0.99
// const AVERAGE_NUMBER_OF_FIELDS_TO_CHANGE = 2
const FIELD_CHANCE_TO_CHANGE_MAP = {
  "online": 0.01,
  "patientInBed": 0.7,
  "bedExitArmed": 0.2,
  "bedExit": 0.5,
  "highFallRisk": 0.05,
  "fallRiskViolation": 0.01,
  "headLeftSideRailUp": 0.9,
  "headRightSideRailUp": 0.9,
  "footLeftSideRailUp": 0.7,
  "footRightSideRailUp": 0.7,
  "bedLow": 0.2,
  "brakesEngaged": 0.05,
  "headOfBed": 0.2
}

const randomKey = (obj) => {
  return Object.keys(obj).sort(function(a,b) {return Math.random() - 0.5;})[0];
}

const bedsByBedId = (beds) => {
  return beds.reduce((acc, bed) => {
    acc[bed.bed] = bed
    return acc
  }, {})
}

const mutateBedField = (bed, fieldToChange) => {
  /*
   * Add a slight bit of "business logic" to make certain field changes less haphazard
   */
  const currentVal = bed[fieldToChange]
  if(fieldToChange === "patientInBed" && currentVal === true && bed["bedExitArmed"] === true) {
    bed["patientInBed"] = false
    bed["bedExit"] = true
  } else if (fieldToChange === "bedExit" && bed["bedExitArmed"] === true) {
    bed["bedExit"] = false
  } else if (fieldToChange === "bedExit" && bed["bedExitArmed"] === false) {
    bed["bedExit"] = !currentVal
    bed["patientInBed"] = !bed["bedExit"]
  }
  else {
    bed[fieldToChange] = !currentVal
  }
}

const demoChangeBeds = (beds) => {
  const bedsShouldChange = Math.random() <= CHANCE_OF_BED_CHANGE
  console.log('demoChangeBeds', 'bedsShouldChange', bedsShouldChange)

  const orderBeds = (bedColl) => {
    const indexedBeds = bedsByBedId(bedColl)
    const bedsInOriginalOrder = TEMP_BEDS.beds.map(b => {
      return indexedBeds[b.bed]
    })
    return bedsInOriginalOrder
  }

  if(bedsShouldChange) {
    const fieldToChange = randomKey(FIELD_CHANCE_TO_CHANGE_MAP)
    const fieldShouldChange = Math.random() <= FIELD_CHANCE_TO_CHANGE_MAP[fieldToChange]
    console.log('demoChangeBeds', 'fieldToChange', fieldToChange, 'fieldShouldChange', fieldShouldChange)
    if(fieldShouldChange) {
      const indexedBeds = bedsByBedId(beds)
      console.log('indexedBeds', Object.keys(indexedBeds))
      const bedIdToChange = randomKey(indexedBeds)
      const bed = indexedBeds[bedIdToChange]
      console.log('bedIdToChange', bedIdToChange, 'bed', bed)
      console.log('demoChangeBeds', 'bedIdToChange', bedIdToChange, 'currentVal', bed[fieldToChange], 'newVal', !bed[fieldToChange])
      mutateBedField(bed, fieldToChange)
      return orderBeds(Object.values(indexedBeds))
    }
  }

  return orderBeds(beds)
}

//////////////////////////////////////////////////////////////////////////////
// Page

export default function Landing() {
    // const updateData = (_: any) => { }

    // const [allTouchConfigVisible, setAllTouchConfigVisible] = useState(false)
    // const [sipConfigVisible, setSipConfigVisible] = useState(false)

    // const [availableVersions, setAvailableVersion] = useState<string[]>([])
    // const [installationPackage, setInstallationPackage] = useState("")
    // const [imagePackage, setImagePackage] = useState("")
    // const [submitError, setSubmitError] = useState(null)
    // const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     async function fetchData() {
    //         const { data } = await axios.get("http://localhost:5555/api/releases")
    //         setAvailableVersion(data.map((value) => value.name))
    //     }

    //     // fetchData()
    // }, [])


    // const floors = TEMP_FLOORS
    const [beds, setBeds] = useState(TEMP_BEDS.beds)
    const [showLogin, setShowLogin] = useState(true)

    const handleSignIn = (e) => {
      e.preventDefault()
      setShowLogin(false)
      return false
    }

    useEffect(() => {
      const interval = setInterval(() => {
        setBeds(demoChangeBeds(beds));
      }, BED_CHANGE_RATE_MS)

      return () => clearInterval(interval)
    }, [beds])

    return (<>
      <style>{`
        .card-body {
          padding-top: 10px !important;
        }
          table.bed-monitor thead th{
            font-size: 1.2em !important;
            padding-bottom: 0.25rem;
            padding-top: 0.25rem;
          }
          table.bed-monitor thead tr:last-child th{
            border-bottom-width: 2px !important;
          }
          table.bed-monitor td {
            font-size: 1.1em !important;
            border-top-color: rgb(222, 226, 230) !important;
            border-top-style: solid !important;
            border-top-width: 1px !important;
          }
          tr.fallRiskViolation {
            background-color: #FFF093;
          }
          td.fallRiskViolation {
            background-color: #f74c00;
            color: white !important;
          }
          .text-danger {
            color: #f74c00 !important;
          }
          tr.offline {
            background-color: #ddd
          }
          .text-tertiary {
            color: #666
          }
      `}
      </style>
        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"

                data-bs-target="#kt_alairo_setup"
                aria-expanded="true"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0" style={{display: "flex", alignItems: "center"}}>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('media/alairo-logo-bg.png')}
                        className='h-25px app-sidebar-logo-default theme-light-show'
                      />
                      <div style={{width:"240px"}}></div>
                    <div>Hospital</div>
                    <div style={{width:"20px"}}></div>
                    <select id="exampleFormControlInput1" className="form-select" aria-label="Select example">
                      <option>Central West</option>
                      <option value="1">Central East</option>
                    </select>
                    <div style={{width:"120px"}}></div>
                    <div>Floor</div>
                    <div style={{width:"20px"}}></div>
                    <select id="exampleFormControlInput1" className="form-select" aria-label="Select example">
                      <option>5</option>
                      <option value="1">6</option>
                      <option value="2">7</option>
                      <option value="3">8</option>
                    </select>
                    <div style={{width:"120px"}}></div>
                    <div>Unit</div>
                    <div style={{width:"20px"}}></div>
                    <select id="exampleFormControlInput1" className="form-select" aria-label="Select example">
                    <option value="1">ICU</option>
                      <option>Peds</option>

                      <option value="2">ER</option>
                      <option value="3">NICU</option>
                    </select>
                    </h3>
                </div>
            </div>

            <div id="kt_alairo_setup" className="collapse show">
                <div className="card-body border-top p-9">
                    <div className="row mb-6">
                        <table className="table _table-bordered _table-striped _table-row-dashed bed-monitor" style={{"fontSize": "14px", "textAlign": "center"}}>
                          <thead>
                            <tr className="fw-bold">
                              <th colSpan={10}></th>
                              <th colSpan={2}>Head Rails</th>
                              <th colSpan={2}>Foot Rails</th>
                              {/* <th colSpan={2}>Siderails</th> */}
                            </tr>
                            <tr className="fw-bold">
                              <th style={{"whiteSpace": "nowrap"}}>Bed</th>
                              {/* <th>Online</th> */}
                              <th>Patient in Bed</th>
                              <th>Sex</th>
                              <th>Weight</th>
                              <th>Exit Armed</th>
                              <th>Bed Exit</th>
                              <th>High Fall Risk</th>
                              {/* <th>Fall Risk Violation</th> */}
                              <th>Head of Bed</th>
                              <th>Bed Low</th>
                              <th>Brakes</th>
                              <th>L</th>
                              <th>R</th>
                              <th>L</th>
                              <th>R</th>
                              {/* <th>Left</th>
                              <th>Right</th> */}
                            </tr>
                          </thead>
                          <tbody style={{"fontSize": "16px"}}>
                            {beds.map(bed => {
                              return bed.online === false ?
                                <tr key={"beds_"+bed.bed} className="offline text-danger">
                                  <td style={{"whiteSpace": "nowrap"}}><strong>{bed.bed}</strong></td>
                                  <td style={{"textTransform": "uppercase"}}>Offline</td>
                                  <td colSpan={12}></td>
                                </tr>
                              : <tr key={"beds_"+bed.bed} className={bed.fallRiskViolation ? "table-warning" : ""}>
                                  <td><strong>{bed.bed}</strong></td>
                                  {/* <td><FontAwesomeIcon icon={faWifi} className="text-success" /></td> */}
                                  <td>{bed.patientInBed === true ? (<FontAwesomeIcon icon={faBed} className="text-primary" />) : (<FontAwesomeIcon icon={faXmark} className="text-warning" />)}</td>
                                  <td>{bed.sex !== null ? (<FontAwesomeIcon icon={bed.sex === 'male' ? faMars : faVenus} className="text-tertiary" />) : ''}</td>
                                  <td>{bed.weight}</td>
                                  <td>{bed.bedExitArmed === true ? (<FontAwesomeIcon icon={faBell} className="text-primary" />) : (<FontAwesomeIcon icon={faBellSlash} className="text-warning" />)}</td>
                                  <td>{bed.bedExit === true ? (<FontAwesomeIcon icon={faShoePrints} className="text-danger" />) : ''}</td>
                                  {bed.fallRiskViolation === true ? (<td className="fallRiskViolation"><FontAwesomeIcon icon={faTriangleExclamation} /> <strong>FALL</strong> <FontAwesomeIcon icon={faTriangleExclamation} /></td>) : bed.highFallRisk === true ? (<td><FontAwesomeIcon icon={faPersonFalling} className="text-warning" /></td>) : <td></td>}
                                  {/* {bed.fallRiskViolation === true ? (<td className="fallRiskViolation"><FontAwesomeIcon icon={faPersonFallingBurst} /></td>) : <td></td>} */}
                                  <td style={{padding: 6}}><div style={{height: 32, width: 36, margin: "auto"}}>{bed.headOfBed === true ? (
                                    <svg id="prep_for_SVG_export" xmlns="http://www.w3.org/2000/svg" viewBox="00 75 512 450"><g id="frame_n_mattress"><path d="M437.24,170l-24.38-27.07c-1.61-1.79-4.37-1.93-6.15-.32l-17.27,15.55c2.34-4.8,1.7-10.74-2.09-14.95-4.99-5.54-13.53-5.99-19.07-1l-51.71,46.56c-5.54,4.99-5.99,13.52-1,19.07,2.67,2.96,6.34,4.47,10.04,4.47,1.56,0,3.12-.27,4.61-.82l-72.68,65.44H51.28c-2.41,0-4.36,1.95-4.36,4.36v36.43c0,2.41,1.95,4.36,4.36,4.36h221.92c.09,0,.18-.02.27-.03.16,0,.31-.02.47-.05.14-.02.27-.06.4-.1.14-.04.28-.08.41-.13.14-.05.26-.12.39-.18.12-.06.24-.12.36-.2.13-.08.26-.18.38-.28.07-.06.15-.1.22-.16l160.8-144.78c1.79-1.61,1.93-4.37.32-6.15Z" fill="#2e2e2e" stroke-width="0"/></g><rect width="512" height="512" fill="none" stroke-width="0"/><path d="M478.42,242.29c-5.52,0-10,4.48-10,10v88.12H38.91v-35.67c0-5.52-4.48-10-10-10s-10,4.48-10,10v116.18c0,5.52,4.48,10,10,10s10-4.48,10-10v-24.4h429.51v24.4c0,5.52,4.48,10,10,10s10-4.48,10-10v-168.63c0-5.52-4.48-10-10-10ZM38.91,376.52v-16.12h429.51v16.12H38.91Z" fill="#7a7a7a" stroke-width="0"/></svg>
                                  ) : (<svg id="prep_for_SVG_export" xmlns="http://www.w3.org/2000/svg" viewBox="0 75 512 450"><g id="frame_n_mattress"><path d="M363.04,276.91c-3.47,0-6.64-1.31-9.03-3.46-2.74-2.47-4.47-6.05-4.47-10.03,0-7.46,6.04-13.5,13.5-13.5h69.58c7.46,0,13.5,6.04,13.5,13.5,0,7.46-6.04,13.5-13.5,13.5h0s24.98,0,24.98,0c2.41,0,4.36,1.95,4.36,4.36v36.43c0,2.41-1.95,4.36-4.36,4.36H51.28c-2.41,0-4.36-1.95-4.36-4.36v-36.43c0-2.41,1.95-4.36,4.36-4.36h311.76Z" fill="#2e2e2e" stroke-width="0"/></g><rect width="512" height="512" fill="none" stroke-width="0"/><path d="M478.42,242.29c-5.52,0-10,4.48-10,10v88.12H38.91v-35.67c0-5.52-4.48-10-10-10s-10,4.48-10,10v116.18c0,5.52,4.48,10,10,10s10-4.48,10-10v-24.4h429.5v24.4c0,5.52,4.48,10,10,10s10-4.48,10-10v-168.63c0-5.52-4.48-10-10-10ZM38.91,376.52v-16.12h429.5v16.12H38.91Z" fill="#7a7a7a" stroke-width="0"/></svg>)
                                }</div></td>

                                  <td>{bed.bedLow === true ? (<FontAwesomeIcon icon={faArrowDown} className="text-tertiary" />) : (<FontAwesomeIcon icon={faMinus} className="text-secondary" />)}</td>
                                  <td>{bed.brakesEngaged === true ? (<FontAwesomeIcon icon={faLock} className="text-primary" />) : (<FontAwesomeIcon icon={faLockOpen} className="text-danger" />)}</td>
                                  <td>{bed.headLeftSideRailUp === true ? (<FontAwesomeIcon icon={faArrowUp} className="text-tertiary" />) : (<FontAwesomeIcon icon={faArrowDown} className="text-warning" />)}</td>
                                  <td>{bed.headRightSideRailUp === true ? (<FontAwesomeIcon icon={faArrowUp} className="text-tertiary" />) : (<FontAwesomeIcon icon={faArrowDown} className="text-warning" />)}</td>
                                  <td>{bed.footLeftSideRailUp === true ? (<FontAwesomeIcon icon={faArrowUp} className="text-tertiary" />) : (<FontAwesomeIcon icon={faArrowDown} className="text-warning" />)}</td>
                                  <td>{bed.footRightSideRailUp === true ? (<FontAwesomeIcon icon={faArrowUp} className="text-tertiary" />) : (<FontAwesomeIcon icon={faArrowDown}  className="text-warning" />)}</td>
{/*
                                  <td style={{padding: 4}}>
                                    <div style={{width: 50, height: 40, margin: "auto"}}>
                                      <svg id="rail_left_up_up" xmlns="http://www.w3.org/2000/svg" viewBox="0 75 512 450" style={{width: "100%", height: "100%"}}>
                                        <g id="frame_n_mattress">
                                          <path d="M74.76,170l24.38-27.07c1.61-1.79,4.37-1.93,6.15-.32l17.27,15.55c-2.34-4.8-1.7-10.74,2.09-14.95,4.99-5.54,13.53-5.99,19.07-1l51.71,46.56c5.54,4.99,5.99,13.52,1,19.07-2.67,2.96-6.34,4.47-10.04,4.47-1.56,0-3.12-.27-4.61-.82l72.68,65.44h206.27c2.41,0,4.36,1.95,4.36,4.36v36.43c0,2.41-1.95,4.36-4.36,4.36h-221.92c-.09,0-.18-.02-.27-.03-.16,0-.31-.02-.47-.05-.14-.02-.27-.06-.4-.1-.14-.04-.28-.08-.41-.13-.14-.05-.26-.12-.39-.18-.12-.06-.24-.12-.36-.2-.13-.08-.26-.18-.38-.28-.07-.06-.15-.1-.22-.16L75.08,176.15c-1.79-1.61-1.93-4.37-.32-6.15Z" fill="#7a7a7a" stroke-width="0"/>
                                        </g>
                                        {bed.footLeftSideRailUp === true ? (<><path id="foot_up" d="M347.12,228.29c-35.26,0-57.6,16.52-70.14,30.39-6.9,7.63-12.64,16.49-16.61,25.64-3.55,8.18-5.5,16.2-5.5,22.59v17.43c0,7.84,6.38,14.21,14.21,14.21h187.27c7.84,0,14.21-6.38,14.21-14.21v-81.83c0-7.84-6.38-14.21-14.21-14.21h-109.24ZM443.34,289.04h-147.85c-1.77,0-3.21-1.44-3.21-3.21,0-7.13,6.5-15.41,12.94-20.46,7.6-5.95,21.35-13.05,43.73-13.05h94.39c1.77,0,3.21,1.44,3.21,3.21v30.3c0,1.77-1.44,3.21-3.21,3.21h0Z" fill="#fff" stroke-width="0"/>
                                          <g id="foot_up-2">
                                            <path d="M260.38,306.9v17.43c0,4.81,3.9,8.71,8.71,8.71h187.27c4.81,0,8.71-3.9,8.71-8.71v-81.83c0-4.81-3.9-8.71-8.71-8.71h-109.24c-60.5,0-86.74,51.83-86.74,73.11ZM286.78,285.83c0-14.09,19.41-39.01,62.17-39.01h94.39c4.81,0,8.71,3.9,8.71,8.71v30.3c0,4.81-3.9,8.71-8.71,8.71h-147.85c-4.81,0-8.71-3.9-8.71-8.71Z" fill="#b9d44e" stroke-width="0"/>
                                          </g></>) : (<><path id="foot_down" d="M347.12,283.09c-35.26,0-57.6,16.52-70.14,30.39-6.9,7.63-12.64,16.49-16.61,25.64-3.55,8.18-5.5,16.2-5.5,22.59v17.43c0,7.84,6.38,14.21,14.21,14.21h187.27c7.84,0,14.21-6.38,14.21-14.21v-81.83c0-7.84-6.38-14.21-14.21-14.21h-109.24ZM443.34,339.53h-145.69c-1.77,0-3.21-1.44-3.21-3.21,0-9.87,17.76-29.21,58.82-29.21h90.09c1.77,0,3.21,1.44,3.21,3.21v25.99c0,1.77-1.44,3.21-3.21,3.21h0Z" fill="#fff" stroke-width="0" />
                                          <g id="foot_down-2">
                                            <path d="M260.38,361.7c0,7.6,0,17.43,0,17.43,0,4.81,3.9,8.71,8.71,8.71h187.27c4.81,0,8.71-3.9,8.71-8.71v-81.83c0-4.81-3.9-8.71-8.71-8.71h-109.24c-60.5,0-86.74,51.83-86.74,73.11ZM288.93,336.32c0-14.09,21.56-34.71,64.32-34.71h90.09c4.81,0,8.71,3.9,8.71,8.71v25.99c0,4.81-3.9,8.71-8.71,8.71h-145.69c-4.81,0-8.71-3.9-8.71-8.71Z" fill="#d1499a" stroke-width="0" />
                                          </g></>)}

                                        {bed.headLeftSideRailUp === true ? (<><path id="head_up" d="M114.32,94.88c-4.02,0-7.88,1.71-10.57,4.7l-54.75,60.81c-5.24,5.82-4.77,14.83,1.05,20.08l161.57,145.48c2.61,2.35,5.99,3.65,9.51,3.65,4.02,0,7.88-1.71,10.57-4.7l11.66-12.95c4.28-4.75,8.19-12.02,11.03-20.47,3.17-9.45,4.84-19.88,4.81-30.16-.04-18.69-5.59-45.92-31.79-69.51l-103.58-93.27c-2.61-2.35-5.99-3.65-9.51-3.65h0ZM231.6,267.51c-.56,0-1.39-.14-2.15-.83l-132.28-119.1c-.64-.57-1.01-1.36-1.06-2.22-.04-.86.25-1.68.82-2.32l15.95-17.72c.83-.93,1.86-1.06,2.39-1.06.56,0,1.39.14,2.15.83l89.35,80.45c13.61,12.25,23.45,26.03,27.73,38.8,2.42,7.24,3.74,17.38-.51,22.11-.83.93-1.86,1.06-2.39,1.06h0Z" fill="#fff" stroke-width="0"/>
                                          <g id="head_up-2">
                                            <path d="M223.73,195.88c-39.99-36.01-103.58-93.27-103.58-93.27-3.58-3.22-9.09-2.93-12.31.65l-54.75,60.81c-3.22,3.58-2.93,9.09.64,12.31l161.57,145.48c3.58,3.22,9.09,2.93,12.31-.65,0,0,6.58-7.3,11.66-12.95,14.24-15.81,29.42-71.9-15.54-112.38ZM225.77,270.78l-132.28-119.1c-3.58-3.22-3.87-8.73-.65-12.31l15.95-17.72c3.22-3.58,8.73-3.87,12.31-.65l89.35,80.45c31.78,28.61,37.04,58.21,27.62,68.67-3.22,3.58-8.73,3.87-12.31.65Z" fill="#b9d44e" stroke-width="0"/>
                                          </g></>) : (<><path id="head_down" d="M88.94,139.96c-4.02,0-7.88,1.71-10.57,4.7l-54.75,60.81c-2.54,2.82-3.83,6.46-3.63,10.26.2,3.79,1.86,7.28,4.68,9.82l161.57,145.48c2.61,2.35,5.99,3.65,9.51,3.65,4.02,0,7.88-1.71,10.57-4.7l11.66-12.95c4.28-4.75,8.19-12.02,11.03-20.47,3.17-9.45,4.84-19.88,4.81-30.16-.04-18.69-5.59-45.92-31.79-69.51l-103.58-93.27c-2.61-2.35-5.99-3.65-9.51-3.65h0ZM206.22,312.59c-.56,0-1.39-.14-2.15-.83l-132.28-119.1c-1.32-1.19-1.42-3.22-.24-4.54l15.95-17.72c.83-.93,1.86-1.06,2.39-1.06.56,0,1.39.14,2.15.83l89.35,80.45c13.61,12.25,23.45,26.03,27.73,38.8,2.42,7.24,3.74,17.38-.51,22.11-.83.93-1.86,1.06-2.39,1.06h0Z" fill="#fff" stroke-width="0" />
                                          <g id="head_down-2">
                                            <path d="M198.35,240.96c-39.99-36.01-103.58-93.27-103.58-93.27-3.58-3.22-9.09-2.93-12.31.65l-54.75,60.81c-3.22,3.58-2.93,9.09.64,12.31l161.57,145.48c3.58,3.22,9.09,2.93,12.31-.65,0,0,6.58-7.3,11.66-12.95,14.24-15.81,29.42-71.9-15.54-112.38ZM200.39,315.86l-132.28-119.1c-3.58-3.22-3.87-8.73-.65-12.31l15.95-17.72c3.22-3.58,8.73-3.87,12.31-.65l89.35,80.45c31.78,28.61,37.04,58.21,27.62,68.67-3.22,3.58-8.73,3.87-12.31.65Z" fill="#d1499a" stroke-width="0" />
                                          </g></>)}
                                        <rect y="0" width="512" height="512" transform="translate(512 512) rotate(-180)" fill="none" stroke-width="0"/>
                                        <path d="M23.58,252.29v168.63c0,5.52,4.48,10,10,10s10-4.48,10-10v-20.4h429.51v20.4c0,5.52,4.48,10,10,10,5.52,0,10-4.48,10-10v-116.18c0-5.52-4.48-10-10-10-5.52,0-10,4.48-10,10v35.67H43.58v-88.12c0-5.52-4.48-10-10-10s-10,4.48-10,10ZM43.58,380.52v-20.12h429.51v20.12H43.58Z" fill="#2e2e2e" stroke-width="0"/>
                                      </svg>
                                    </div>
                                  </td>
                                  <td style={{padding: 4}}>
                                    <div style={{width: 50, height: 40, margin: "auto"}}>
                                      <svg id="rail_right_down_down" xmlns="http://www.w3.org/2000/svg" viewBox="0 75 512 450" style={{width: "100%", height: "100%"}}>
                                        <path d="M437.24,170l-24.38-27.07c-1.61-1.79-4.37-1.93-6.15-.32l-17.27,15.55c2.34-4.8,1.7-10.74-2.09-14.95-4.99-5.54-13.53-5.99-19.07-1l-51.71,46.56c-5.54,4.99-5.99,13.52-1,19.07,2.67,2.96,6.34,4.47,10.04,4.47,1.56,0,3.12-.27,4.61-.82l-72.68,65.44H51.28c-2.41,0-4.36,1.95-4.36,4.36v36.43c0,2.41,1.95,4.36,4.36,4.36h221.92c.09,0,.18-.02.27-.03.16,0,.31-.02.47-.05.14-.02.27-.06.4-.1.14-.04.28-.08.41-.13.14-.05.26-.12.39-.18.12-.06.24-.12.36-.2.13-.08.26-.18.38-.28.07-.06.15-.1.22-.16l160.8-144.78c1.79-1.61,1.93-4.37.32-6.15Z" fill="#7a7a7a" stroke-width="0"/>
                                        <rect width="512" height="512" fill="none" stroke-width="0"/>
                                        <path d="M478.42,242.29c-5.52,0-10,4.48-10,10v88.12H38.91v-35.67c0-5.52-4.48-10-10-10s-10,4.48-10,10v116.18c0,5.52,4.48,10,10,10s10-4.48,10-10v-20.4h429.5v20.4c0,5.52,4.48,10,10,10s10-4.48,10-10v-168.63c0-5.52-4.48-10-10-10ZM38.91,380.52v-20.12h429.5v20.12H38.91Z" fill="#2e2e2e" stroke-width="0"/>
                                        {bed.headRightSideRailUp === true ? (<><path id="head_up"
    d="M397.68,94.88c-3.52,0-6.89,1.3-9.51,3.65l-103.58,93.27c-26.2,23.59-31.75,50.82-31.79,69.51-.02,10.28,1.64,20.71,4.81,30.16,2.84,8.45,6.75,15.72,11.03,20.47l11.66,12.95c2.69,2.99,6.54,4.7,10.57,4.7,3.52,0,6.89-1.3,9.51-3.65l161.57-145.48c5.82-5.24,6.3-14.25,1.05-20.08l-54.75-60.81c-2.69-2.99-6.54-4.7-10.57-4.7h0ZM280.4,267.51c-.54,0-1.56-.14-2.39-1.06-4.26-4.73-2.94-14.86-.51-22.11,4.27-12.77,14.12-26.55,27.73-38.8l89.35-80.45c.76-.68,1.59-.83,2.15-.83.54,0,1.56.14,2.39,1.06l15.95,17.72c.57.64.87,1.46.82,2.32-.04.86-.42,1.65-1.06,2.22l-132.28,119.1c-.76.68-1.59.83-2.15.83h0Z"
    fill="#fff" stroke-width="0" />
  <g id="head_up-2">
    <path
      d="M272.73,308.26c5.09,5.65,11.66,12.95,11.66,12.95,3.22,3.58,8.73,3.87,12.31.65l161.57-145.48c3.58-3.22,3.87-8.73.64-12.31l-54.75-60.81c-3.22-3.58-8.73-3.87-12.31-.65,0,0-63.6,57.26-103.58,93.27-44.96,40.48-29.78,96.56-15.54,112.38ZM273.92,270.13c-9.43-10.47-4.16-40.06,27.62-68.67l89.35-80.45c3.58-3.22,9.09-2.93,12.31.65l15.95,17.72c3.22,3.58,2.93,9.09-.65,12.31l-132.28,119.1c-3.58,3.22-9.09,2.93-12.31-.65Z"
      fill="#b9d44e" stroke-width="0" />
  </g></>) : (<><path id="head_down" d="M423.06,139.96c-3.52,0-6.89,1.3-9.51,3.65l-103.58,93.27c-26.2,23.59-31.75,50.82-31.79,69.51-.02,10.28,1.64,20.71,4.81,30.16,2.84,8.45,6.75,15.72,11.03,20.47l11.66,12.95c2.69,2.99,6.54,4.7,10.57,4.7,3.52,0,6.89-1.3,9.51-3.65l161.57-145.48c2.82-2.54,4.49-6.03,4.68-9.82.2-3.79-1.09-7.43-3.63-10.26l-54.75-60.81c-2.69-2.99-6.54-4.7-10.57-4.7h0ZM305.78,312.59c-.54,0-1.56-.14-2.39-1.06-4.26-4.73-2.94-14.86-.51-22.11,4.27-12.77,14.12-26.55,27.73-38.8l89.35-80.45c.76-.68,1.59-.83,2.15-.83.54,0,1.56.14,2.39,1.06l15.95,17.72c1.19,1.32,1.08,3.35-.24,4.54l-132.28,119.1c-.76.68-1.59.83-2.15.83h0Z" fill="#fff" stroke-width="0"/>
                                        <g id="head_down-2">
                                          <path d="M298.11,353.34c5.09,5.65,11.66,12.95,11.66,12.95,3.22,3.58,8.73,3.87,12.31.65l161.57-145.48c3.58-3.22,3.87-8.73.64-12.31l-54.75-60.81c-3.22-3.58-8.73-3.87-12.31-.65,0,0-63.6,57.26-103.58,93.27-44.96,40.48-29.78,96.56-15.54,112.38ZM299.3,315.21c-9.43-10.47-4.16-40.06,27.62-68.67l89.35-80.45c3.58-3.22,9.09-2.93,12.31.65l15.95,17.72c3.22,3.58,2.93,9.09-.65,12.31l-132.28,119.1c-3.58,3.22-9.09,2.93-12.31-.65Z" fill="#d1499a" stroke-width="0"/>
                                        </g></>)}

                                        {bed.footRightSideRailUp === true ? (<><path id="foot_up"
    d="M164.88,228.29H55.64c-7.84,0-14.21,6.38-14.21,14.21v81.83c0,7.84,6.38,14.21,14.21,14.21h187.27c7.84,0,14.21-6.38,14.21-14.21v-17.43c0-6.39-1.95-14.41-5.5-22.59-3.97-9.14-9.71-18.01-16.61-25.64-12.54-13.86-34.88-30.39-70.14-30.39h0ZM68.66,289.04c-1.77,0-3.21-1.44-3.21-3.21v-30.3c0-1.77,1.44-3.21,3.21-3.21h94.39c22.38,0,36.13,7.1,43.73,13.05,6.44,5.05,12.94,13.33,12.94,20.46,0,1.77-1.44,3.21-3.21,3.21H68.66Z"
    fill="#fff" stroke-width="0" />
  <g id="foot_up-2">
    <path
      d="M164.88,233.79H55.64c-4.81,0-8.71,3.9-8.71,8.71v81.83c0,4.81,3.9,8.71,8.71,8.71h187.27c4.81,0,8.71-3.9,8.71-8.71v-17.43c0-21.28-26.25-73.11-86.74-73.11ZM216.51,294.54H68.66c-4.81,0-8.71-3.9-8.71-8.71v-30.3c0-4.81,3.9-8.71,8.71-8.71h94.39c42.76,0,62.17,24.93,62.17,39.01,0,4.81-3.9,8.71-8.71,8.71Z"
      fill="#b9d44e" stroke-width="0" />
  </g></>) : (<><path id="foot_down" d="M164.88,283.09H55.64c-7.84,0-14.21,6.38-14.21,14.21v81.83c0,7.84,6.38,14.21,14.21,14.21h187.27c7.84,0,14.21-6.38,14.21-14.21v-17.43c0-6.39-1.95-14.41-5.5-22.59-3.97-9.14-9.71-18.01-16.61-25.64-12.54-13.86-34.88-30.39-70.14-30.39h0ZM68.66,339.53c-1.77,0-3.21-1.44-3.21-3.21v-25.99c0-1.77,1.44-3.21,3.21-3.21h90.09c41.06,0,58.82,19.34,58.82,29.21,0,1.77-1.44,3.21-3.21,3.21H68.66Z" fill="#fff" stroke-width="0"/>
                                        <g id="foot_down-2">
                                          <path d="M164.88,288.59H55.64c-4.81,0-8.71,3.9-8.71,8.71v81.83c0,4.81,3.9,8.71,8.71,8.71h187.27c4.81,0,8.71-3.9,8.71-8.71v-17.43c0-21.28-26.25-73.11-86.74-73.11ZM214.35,345.03H68.66c-4.81,0-8.71-3.9-8.71-8.71v-25.99c0-4.81,3.9-8.71,8.71-8.71h90.09c42.76,0,64.32,20.62,64.32,34.71,0,4.81-3.9,8.71-8.71,8.71Z" fill="#d1499a" stroke-width="0"/>
                                        </g></>)}
                                      </svg>
                                    </div>
                                  </td> */}
                                </tr>
                            })}
                          </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal" tabIndex={-1} role="dialog" style={{display: showLogin === true ? "block" : "none", backgroundColor: "#fff"}}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <img alt='Logo' src={toAbsoluteUrl('media/alairo-logo-bg.png')} className='h-25px app-sidebar-logo-default theme-light-show'/>
                </div>
                <h5 className="modal-title">Sign In</h5>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-body">
              <form onSubmit={handleSignIn}>
                <div className="form-group" style={{marginBottom: 16}}>
                  <label htmlFor="exampleInputEmail1">Email Address</label>
                  <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" />
                </div>
                <div className="form-group" style={{marginBottom: 16}}>
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>
                <button type="submit" className="btn btn-primary">Sign In</button>
              </form>
              </div>
              {/* <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => setShowLogin(false)}>Sign In</button>
              </div> */}
            </div>
          </div>
        </div>


    </>)
}
